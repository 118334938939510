import React, { useRef } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { PLATFORM_WIDTH } from "../../constants/ui";
import type { CorouselItem } from "../util";

interface Props {
  list: CorouselItem[];
  onChange: (data: Pick<CorouselItem, "render">) => void;
}

export const CarouselPicker: React.FC<Props> = ({ list, onChange }) => {
  const ITEM_SIZE = PLATFORM_WIDTH * 0.38;
  const ITEM_SPACING = (PLATFORM_WIDTH - ITEM_SIZE) / 2;
  const scrollX = useRef(new Animated.Value(0)).current;
  return (
    <View style={styles.wrapper}>
      <Animated.FlatList
        horizontal
        data={list}
        keyExtractor={(key) => key.id}
        extraData={(key: CorouselItem) => key.id}
        showsHorizontalScrollIndicator={false}
        bounces={false}
        contentContainerStyle={{ paddingHorizontal: ITEM_SPACING }}
        snapToInterval={ITEM_SIZE}
        onMomentumScrollEnd={(e) => {
          const current = Math.round(e.nativeEvent.contentOffset.x / ITEM_SIZE);
          onChange(list[current].render);
        }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: scrollX } } }],
          { useNativeDriver: true }
        )}
        renderItem={({ item, index }) => {
          const inputRange = [
            (index - 1) * ITEM_SIZE,
            index * ITEM_SIZE,
            (index + 1) * ITEM_SIZE,
          ];
          const opacity = scrollX.interpolate({
            inputRange,
            outputRange: [0.4, 1, 0.4],
          });
          const scale = scrollX.interpolate({
            inputRange,
            outputRange: [0.7, 1, 0.7],
          });
          return (
            <View style={{ width: ITEM_SIZE }}>
              <Animated.Text
                style={[styles.listItem, { opacity, transform: [{ scale }] }]}
              >
                {item.render}
              </Animated.Text>
            </View>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    color: "#000",
    alignItems: "center",
    overflow: "hidden",
  },
  listItem: {
    color: "#000",
    fontSize: 70,
    marginHorizontal: 20,
  },
});
