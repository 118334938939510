import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Slider } from "react-native-elements";
import { COLORS, FONTS } from "../../constants/ui";

interface Props {
  label: string;
  value: number;
  onChange: () => void;
}

export const LabelledSlider: React.FC<Props> = ({ label, value, onChange }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>
        {label} <Text style={styles.score}>{value?.toFixed(2)} / 10</Text>
      </Text>
      <Slider
        onValueChange={onChange}
        value={value}
        maximumValue={10}
        minimumValue={0}
        thumbStyle={styles.thumbStyle}
        trackStyle={styles.trackStyle}
        minimumTrackTintColor={COLORS.FADED_BLUE}
        maximumTrackTintColor={COLORS.WHITE}
        allowTouchTrack
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  label: {
    fontFamily: FONTS.LexendDeca_400,
    color: COLORS.BLACK,
  },
  score: {
    fontWeight: "bold",
  },
  trackStyle: { backgroundColor: COLORS.FADED_BLUE, height: 5 },
  thumbStyle: {
    width: 20,
    height: 20,
    backgroundColor: COLORS.FADED_BLUE,
  },
});
