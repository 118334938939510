import { Dimensions, Platform } from "react-native";
let { width } = Dimensions.get("window");
const MAX_DESKTOP_WIDTH = Platform.OS === "web" && width > 525 ? 525 : width;
export const PLATFORM_WIDTH = Platform.OS === "web" ? MAX_DESKTOP_WIDTH : width;

export const COLORS = {
  LIGHTBLUE: "#F1EDFD",
  DARKBLUE: "#3B0FB1",
  FADED_BLUE: "#8C72D3",
  TEAL: "#D3FFE4",
  BLACK: "#000",
  WHITE: "#fff",
  RED: "#F20000",
};
export const FONTS = {
  LexendDeca_400: "LexendDeca_400Regular",
  LexendDeca_600: "LexendDeca_600SemiBold",
};

export const MISC = {
  marginSmall: 10,
  paddingRegular: 24,
  borderRadius: 15,
};
