import React, { useState, forwardRef, useRef, useEffect } from "react";
import { Dimensions, StyleSheet, View, Text, Animated } from "react-native";
import { Slider } from "react-native-elements";
import { useForm, Controller } from "react-hook-form";
import { Button, CheckmarkButton, RadioGroup } from "../commonUI";
import {
  UserMetrics,
  RadioItem,
  AdditionalHealthCondition,
  animate,
} from "../util";
import { COLORS, FONTS, MISC, PLATFORM_WIDTH } from "../../constants/ui";

const radioGroupOptions: RadioItem[] = [
  { text: "Female", selectionColor: COLORS.TEAL, icon: "md-female" },
  { text: "Male", selectionColor: COLORS.TEAL, icon: "md-male" },
];

const extraConditions: AdditionalHealthCondition[] = [
  AdditionalHealthCondition.Smoker,
  AdditionalHealthCondition.HeavyDrinker,
  AdditionalHealthCondition.Diabetic,
];
interface HealthCheckFormProps {
  onSubmit: (data: UserMetrics) => void;
}
type AnimationState = Record<string, Record<string, Animated.Value>>;

const initialState: AnimationState = {};
extraConditions.forEach((conditionName) => {
  Object.defineProperty(initialState, conditionName, {
    value: {
      plusIconOpacity: new Animated.Value(1),
      checkIconOpacity: new Animated.Value(0),
      checkIconChangeY: new Animated.Value(0),
      plusIconChangeX: new Animated.Value(1),
    },
    writable: true,
    enumerable: true,
    configurable: true,
  });
});

const isExtraCondition = (
  value: string
): value is AdditionalHealthCondition => {
  return extraConditions.some((conditionName) => conditionName === value);
};

let { height, width } = Dimensions.get("window");
const FORM_HEIGHT = height * 0.65;
export const HealthCheckForm = forwardRef<View, HealthCheckFormProps>(
  ({ onSubmit }, forwardedRef) => {
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedConditionArray, setSelectedConditionArray] = useState<
      AdditionalHealthCondition[]
    >([]);
    const [animationState, setAnimationState] =
      useState<AnimationState>(initialState);

    const { control, handleSubmit, getValues } = useForm<UserMetrics>({
      defaultValues: {
        gender: "",
        age: "0",
      },
    });

    function enableButtonOnCondition(condition: boolean) {
      condition ? setButtonDisabled(false) : setButtonDisabled(true);
    }

    const handleExtraConditionsAnimation = (
      newButtonText: AdditionalHealthCondition
    ) => {
      extraConditions.forEach((conditionName) => {
        const buttonSelected =
          !selectedConditionArray.includes(conditionName) &&
          newButtonText === conditionName;
        const buttonUnselected =
          selectedConditionArray.includes(conditionName) &&
          newButtonText === conditionName;

        if (buttonSelected) {
          animate(
            animationState[newButtonText].checkIconOpacity,
            1,
            1000,
            true
          ).start();
          animate(
            animationState[newButtonText].plusIconOpacity,
            0,
            500,
            true
          ).start();
          animate(
            animationState[newButtonText].checkIconChangeY,
            1,
            1000,
            true
          ).start();
          animate(
            animationState[newButtonText].plusIconChangeX,
            0,
            500,
            true
          ).start();
        }
        if (buttonUnselected) {
          animate(
            animationState[conditionName].checkIconOpacity,
            0,
            1000,
            true
          ).start();
          animate(
            animationState[conditionName].plusIconOpacity,
            1,
            500,
            true
          ).start();
          animate(
            animationState[conditionName].checkIconChangeY,
            0,
            1000,
            true
          ).start();
          animate(
            animationState[conditionName].plusIconChangeX,
            1,
            500,
            true
          ).start();
        }
      });
    };

    return (
      <View style={styles.wrapper} ref={forwardedRef}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange } }) => (
            <RadioGroup
              data={radioGroupOptions}
              onSelect={(val) => {
                const userAge = getValues("age");
                onChange(val);
                enableButtonOnCondition(+userAge >= 18);
              }}
            />
          )}
          name="gender"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.sliderWrapper}>
              <Text style={styles.sliderHeading}>Age: {value}</Text>
              <View style={styles.slider}>
                <Slider
                  animateTransitions
                  animationType="spring"
                  maximumTrackTintColor="#fff"
                  maximumValue={100}
                  minimumTrackTintColor={COLORS.FADED_BLUE}
                  minimumValue={0}
                  onValueChange={(val) => {
                    const userAge = getValues("age");
                    const userGender = getValues("gender");
                    onChange(val);
                    enableButtonOnCondition(
                      Boolean(+userAge >= 18 && userGender)
                    );
                  }}
                  orientation="horizontal"
                  step={1}
                  style={{ width: "80%", height: "100%" }}
                  thumbStyle={{
                    height: 34,
                    width: 34,
                    backgroundColor: COLORS.FADED_BLUE,
                  }}
                  thumbTouchSize={{ width: 40, height: 40 }}
                  trackStyle={{ height: 18, borderRadius: 20 }}
                  value={0}
                />
              </View>
            </View>
          )}
          name="age"
        />
        <View style={styles.checkmarkButtonRow}>
          {extraConditions.map((healthCondition) => {
            const conditionItemSelected =
              selectedConditionArray?.includes(healthCondition);
            return (
              <View
                style={{ marginRight: 6, marginBottom: 15 }}
                key={healthCondition}
              >
                <CheckmarkButton
                  onPress={(buttonText) => {
                    if (
                      typeof buttonText === "string" &&
                      isExtraCondition(buttonText)
                    ) {
                      setSelectedConditionArray((prevState) => {
                        let newState: AdditionalHealthCondition[] = [];
                        if (prevState?.includes(buttonText)) {
                          newState = prevState.filter(
                            (selectedCondition) =>
                              selectedCondition !== buttonText
                          );
                        } else {
                          newState = [...prevState, buttonText];
                        }

                        return newState;
                      });
                      handleExtraConditionsAnimation(buttonText);
                    }
                  }}
                  bgColor={conditionItemSelected ? COLORS.TEAL : COLORS.WHITE}
                  textColor={
                    conditionItemSelected ? COLORS.BLACK : COLORS.DARKBLUE
                  }
                  checkIconOpacity={
                    animationState[healthCondition].checkIconOpacity
                  }
                  plusIconOpacity={
                    animationState[healthCondition].plusIconOpacity
                  }
                  checkIconChangeY={
                    animationState[healthCondition].checkIconChangeY
                  }
                  plusIconChangeX={
                    animationState[healthCondition].plusIconChangeX
                  }
                >
                  {healthCondition}
                </CheckmarkButton>
              </View>
            );
          })}
        </View>
        <View style={styles.button}>
          <Button
            color={buttonDisabled ? COLORS.FADED_BLUE : COLORS.WHITE}
            textColor={buttonDisabled ? COLORS.DARKBLUE : COLORS.BLACK}
            onPress={handleSubmit((val) => !buttonDisabled && onSubmit(val))}
          >
            Browse tests
          </Button>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: "space-between",
    height: FORM_HEIGHT,
    width: "100%",
    borderTopRightRadius: 30,
    padding: MISC.paddingRegular,
    backgroundColor: COLORS.DARKBLUE,
    alignSelf: "flex-end",
  },
  sliderWrapper: {
    height: "20%",
    marginBottom: FORM_HEIGHT * 0.1,
    justifyContent: "space-between",
  },
  slider: {
    alignItems: "center",
  },
  sliderHeading: {
    textAlign: "center",
    fontFamily: FONTS.LexendDeca_600,
    color: COLORS.WHITE,
    fontSize: 24,
    marginTop: 10,
  },
  button: {
    marginBottom: MISC.marginSmall,
  },
  checkmarkButtonRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: width > PLATFORM_WIDTH ? "space-between" : "flex-start",
    paddingBottom: 10,
  },
});
