import { Animated } from "react-native";
export function capitalizeCustom(string: string) {
  const words = string.split(" ");
  return words
    .map((word) => {
      const firstLetter = word.slice(0, 1);
      const rest = word.slice(1);
      return `${firstLetter.toUpperCase()}${
        rest === "olmed" ? rest.toUpperCase() : rest
      }`;
    })
    .join(" ");
}

export function calculateOpacityForRange(
  yPosition: number,
  zeroOpacityY: number,
  fullOpacityY: number
) {
  const range = fullOpacityY - zeroOpacityY;
  const relativeCurrentPos = yPosition - zeroOpacityY;
  return relativeCurrentPos / range;
}

export const animate = (
  prop: Animated.Value,
  toValue: number,
  duration: number,
  native: boolean,
  delay?: number
) => {
  return Animated.timing(prop, {
    toValue,
    duration,
    useNativeDriver: native,
    delay: delay,
  });
};
