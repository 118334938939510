import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Text,
  TextInput,
  View,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import { Button } from "../commonUI/Button";
import { COLORS, FONTS, PLATFORM_WIDTH, MISC } from "../../constants/ui";
import type { LoginData } from "..";

let { height } = Dimensions.get("window");

interface Props {
  onSubmit: (x: LoginData) => void;
  logSecret: string;
  passSecret: string;
}

export const LoginForm: React.FC<Props> = ({
  onSubmit,
  logSecret,
  passSecret,
}) => {
  const [enter, setEnter] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<LoginData>({
    defaultValues: {
      login: "",
      password: "",
    },
  });

  const passFocus = () => {
    const { login, password } = getValues();
    if (login === logSecret && password === passSecret) {
      setEnter(true);
    }
  };

  const changeView = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onSubmit(getValues());
    }
  };
  useEffect(() => {
    if (Platform.OS === "web") {
      document.body.addEventListener("keydown", changeView);
      return () => {
        document.body.removeEventListener("keydown", changeView);
      };
    }
  }, []);
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Test Reminder</Text>
      </View>
      <View>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: new RegExp(logSecret),
              message: "Incorrect user name",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={styles.input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder="Login"
            />
          )}
          name="login"
        />
        <View style={styles.errorBox}>
          {errors.login && (
            <Text style={styles.error}>{errors.login.message}</Text>
          )}
        </View>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: new RegExp(passSecret),
              message: "Incorrect password",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              style={styles.input}
              onBlur={passFocus}
              onChangeText={onChange}
              value={value}
              placeholder="Password"
              secureTextEntry
            />
          )}
          name="password"
        />
        <View style={styles.errorBox}>
          {errors.password && (
            <Text style={styles.error}>{errors.password.message}</Text>
          )}
        </View>
      </View>
      <View style={styles.button}>
        <Button
          color={enter ? COLORS.TEAL : COLORS.FADED_BLUE}
          textColor={COLORS.BLACK}
          onPress={handleSubmit((data) => onSubmit(data))}
        >
          Enter
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    width: PLATFORM_WIDTH,
    padding: 20,
    backgroundColor: COLORS.LIGHTBLUE,
  },
  input: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    width: "100%",
    height: 50,
    marginVertical: 20,
    padding: Platform.OS === "web" ? 12 : height * 0.02,
    fontSize: Platform.OS === "web" ? 16 : height * 0.02,
    fontFamily: FONTS.LexendDeca_400,
    color: COLORS.BLACK,
  },
  title: {
    fontFamily: FONTS.LexendDeca_600,
    fontSize: height * 0.05,
    color: COLORS.BLACK,
    marginLeft: "auto",
    marginRight: "auto",
  },
  error: {
    color: COLORS.RED,
    fontFamily: FONTS.LexendDeca_400,
  },
  errorBox: {
    height: 20,
  },
  button: { marginBottom: MISC.marginSmall },
});
