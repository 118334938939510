import React from "react";
import AppLoading from "expo-app-loading";
import { useFonts, Italiana_400Regular } from "@expo-google-fonts/italiana";
import { Poppins_400Regular } from "@expo-google-fonts/poppins";
import {
  LexendDeca_400Regular,
  LexendDeca_600SemiBold,
} from "@expo-google-fonts/lexend-deca";
import { NavigationContainer, LinkingOptions } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Home } from "./Screens/Home";
import { LabDetails } from "./Screens/LabDetails";
import { Login } from "./Screens/Login";
import { Tests } from "./Screens/Tests";
import { TestDetails } from "./Screens/TestDetails";
import { CustomHeader } from "./components";
import { capitalizeCustom } from "./components/util";

import type { RootStackParamList } from "./components/util";

const App: React.FC = () => {
  const Stack = createStackNavigator<RootStackParamList>();
  let [fontsLoaded] = useFonts({
    Italiana_400Regular,
    Poppins_400Regular,
    LexendDeca_400Regular,
    LexendDeca_600SemiBold,
  });

  const linking: LinkingOptions<RootStackParamList> = {
    prefixes: ["https://testreminder.com"],
    config: {
      screens: {
        Home: "/home",
        Login: "login",
        Tests: {
          path: "tests/:gender/:age",
          stringify: {
            gender: (g) => g.toLowerCase(),
          },
        },
        TestDetails: {
          path: "test-details/:testName",
          stringify: {
            testName: (name) => name.split(" ").join(""),
          },
        },
        TermsAndConditions: "terms-and-conditions",
        LabDetails: {
          path: "lab-details/:labName",
          stringify: {
            labName: (name) => name.split(" ").join("-").toLowerCase(),
          },
        },
      },
    },
  };

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <Provider store={store}>
        <NavigationContainer linking={linking}>
          <Stack.Navigator
            initialRouteName="Login"
            screenOptions={{
              header: (props) => <CustomHeader {...props} />,
            }}
          >
            <Stack.Screen
              name="Login"
              component={Login}
              options={{
                title: "Login",
              }}
            />
            <Stack.Screen
              name="Home"
              component={Home}
              options={{
                title: "Home",
              }}
            />
            <Stack.Screen
              name="Tests"
              component={Tests}
              options={{ title: "Recommended Tests" }}
            />
            <Stack.Screen
              name="TestDetails"
              component={TestDetails}
              options={({ route }) => ({
                title: capitalizeCustom(route.params.testName),
              })}
            />
            <Stack.Screen
              name="LabDetails"
              component={LabDetails}
              options={({ route }) => ({
                title: capitalizeCustom(
                  route.params.labName.split("-").join(" ")
                ),
              })}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </Provider>
    );
  }
};

export default App;
