import React, { useState, useEffect, useRef } from "react";
import { StatusBar } from "expo-status-bar";
import {
  FlatList,
  View,
  StyleSheet,
  Platform,
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { TestCard } from "../components";
import { common, men, women } from "../data";
import { PLATFORM_WIDTH, COLORS } from "../constants/ui";
import {
  filterByAge,
  getDataItems,
  calculateOpacityForRange,
} from "../components/util";
import type { DataItem, RootStackParamList } from "../components/util";

type Props = StackScreenProps<RootStackParamList, "Tests">;

const { height } = Dimensions.get("window");

export const Tests: React.FC<Props> = ({ navigation, route }) => {
  const [labTests, setLabTests] = useState<DataItem[]>(() => []);
  const [cardOpacity, setCardOpacity] = useState({
    top: { title: "", val: 1 },
    bottom: { title: "", val: 1 },
  });
  const listItemRef = useRef<View[]>([]);
  const { gender, age } = route.params;
  const sortedData = labTests.sort((a, b) => a.freq - b.freq);

  const handleScroll = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (Platform.OS !== "web") return;
    const { layoutMeasurement } = nativeEvent;
    const padding = 30;
    listItemRef.current.forEach((listItem, idx) => {
      listItem.measureInWindow((x, y, width, height) => {
        const screenTopFullOpacity = height / 2 + padding;
        const screenTopZeroOpacity = -height / 2 - padding;
        const screenBottomFullOpacity =
          layoutMeasurement.height - height / 2 - padding;
        const screenBottomZeroOpacity =
          layoutMeasurement.height + height / 2 + padding;

        if (y > screenTopZeroOpacity && y < screenTopFullOpacity) {
          const topElementOpacity = calculateOpacityForRange(
            y,
            screenTopZeroOpacity,
            screenTopFullOpacity
          );
          setCardOpacity((prev) => {
            return {
              ...prev,
              top: { title: labTests[idx].test, val: topElementOpacity },
            };
          });
        }
        if (y > screenBottomFullOpacity && y < screenBottomZeroOpacity) {
          const bottomElementOpacity = calculateOpacityForRange(
            y,
            screenBottomZeroOpacity,
            screenBottomFullOpacity
          );
          setCardOpacity((prev) => {
            return {
              ...prev,
              bottom: {
                title: labTests[idx].test,
                val: bottomElementOpacity,
              },
            };
          });
        }
      });
    });
  };

  const determineComponentOpacity = (
    dataItem: DataItem,
    values: typeof cardOpacity
  ) => {
    return dataItem.test === values.top.title
      ? cardOpacity.top.val
      : dataItem.test === values.bottom.title
      ? cardOpacity.bottom.val
      : 1;
  };

  useEffect(() => {
    switch (gender.toLowerCase()) {
      case "male":
        const menResults = getDataItems(common, men, age)(filterByAge);
        setLabTests((prev) => {
          prev = [];
          return menResults;
        });
        break;
      case "female":
        const womenResults = getDataItems(common, women, age)(filterByAge);
        setLabTests((prev) => {
          prev = [];
          return womenResults;
        });
        break;
    }
  }, [gender, age]);

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <View style={styles.listWrapper}>
        <FlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={[
            styles.list,
            { height: Platform.OS === "web" ? height * 0.9 : "auto" },
          ]}
          data={sortedData}
          keyExtractor={(item) => item.test}
          renderItem={({ item }) => (
            <TestCard
              onPress={() =>
                navigation.navigate("TestDetails", {
                  testName: item.test,
                })
              }
              itemDetails={item}
              ref={(el) =>
                el &&
                !listItemRef.current.includes(el) &&
                listItemRef.current.push(el)
              }
              opacity={determineComponentOpacity(item, cardOpacity)}
            />
          )}
          onScroll={handleScroll}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: COLORS.LIGHTBLUE,
  },
  listWrapper: {
    width: PLATFORM_WIDTH,
    backgroundColor: COLORS.DARKBLUE,
  },
  list: {
    borderTopRightRadius: 30,
    paddingTop: 20,
    paddingLeft: Platform.OS === "web" ? 0 : 20,
  },
});
