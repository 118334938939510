import { View, Text, Dimensions, StyleSheet, ScrollView } from "react-native";
import { Overlay, Button } from "react-native-elements";
import { COLORS, PLATFORM_WIDTH, FONTS } from "../../constants/ui";

let { width, height } = Dimensions.get("window");

interface TestsListProps {
  tel: string;
  isVisible: boolean;
  onPress: () => void;
  testsList: Array<{ title: string; price: number }>;
}
export const TestsList: React.FC<TestsListProps> = ({
  tel,
  isVisible,
  onPress,
  testsList,
}) => {
  return (
    <Overlay
      isVisible={isVisible}
      onBackdropPress={onPress}
      overlayStyle={styles.modalWrapper}
    >
      <ScrollView>
        <View style={styles.heading}>
          <Text style={styles.headingText}>
            <Text style={styles.telText}>{tel}</Text>
            {"\n"}
            make an appointment or use our intuitive online form
          </Text>
          <Button title="Start making an appointment" type="outline" />
        </View>
        {testsList.map((test) => {
          return (
            <View style={styles.testItem} key={test.title}>
              <Text style={styles.testTitle}>{test.title}</Text>
              <Text style={styles.testPrice}>Cost: {test.price} PLN</Text>
            </View>
          );
        })}
      </ScrollView>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  modalWrapper: {
    width: width > PLATFORM_WIDTH ? PLATFORM_WIDTH * 0.7 : PLATFORM_WIDTH,
    borderRadius: 10,
    backgroundColor: COLORS.LIGHTBLUE,
    maxHeight: height * 0.7,
  },
  heading: {
    marginBottom: 30,
  },
  headingText: {
    fontFamily: FONTS.LexendDeca_600,
    color: COLORS.BLACK,
    fontSize: PLATFORM_WIDTH * 0.03,
    marginBottom: 10,
  },
  telText: {
    display: "flex",
    fontFamily: FONTS.LexendDeca_400,
    fontSize: PLATFORM_WIDTH * 0.07,
  },
  testItem: {
    marginBottom: 10,
  },
  testTitle: {
    fontFamily: FONTS.LexendDeca_600,
    fontWeight: "bold",
    fontSize: PLATFORM_WIDTH * 0.02,
  },
  testPrice: {
    fontFamily: FONTS.LexendDeca_400,
    fontSize: PLATFORM_WIDTH * 0.02,
    fontStyle: "italic",
  },
});
