import { useState } from "react";
import * as Location from "expo-location";
import type { LocationObject } from "expo-location";

export const useGeolocation = () => {
  const [location, setLocation] = useState<LocationObject | null>(null);
  const [errorMsg, setErrorMsg] = useState("");

  const getLocationAsync = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      setErrorMsg("Permission to access location was denied");
      return;
    }

    let location: LocationObject = await Location.getCurrentPositionAsync({});
    setLocation(location);
  };

  return { getLocationAsync, location, errorMsg };
};
