import type { IReview } from "./interfaces";

export class Review implements IReview {
  public score: string;

  constructor(
    public lab_id: string,
    public reviewer: string,
    public reviewText: string,
    public customerService: number,
    public parking: number,
    public waitingTime: number,
    public higene: number,
    public overallSatisfaction: number
  ) {
    this.score = this.calculateScore();
  }

  setReviewer(name: string): void | string {
    if (name.length < 1) return "Please signe the review";
    this.reviewer = name;
  }
  setReviewText(text: string): void | string {
    if (text.length < 2) return "Review too short";
    this.reviewText = text;
  }
  setFeature<T extends keyof IReview>(feature: T, value: number): void {}

  private calculateScore(): string {
    return (
      (this.customerService +
        this.parking +
        this.waitingTime +
        this.higene +
        this.overallSatisfaction) /
      5
    ).toFixed(2);
  }

  toFirestore() {
    return {
      lab_id: this.lab_id,
      reviewer: this.reviewer,
      reviewText: this.reviewText,
      customerService: this.customerService,
      parking: this.parking,
      waitingTime: this.waitingTime,
      higene: this.higene,
      overallSatisfaction: this.overallSatisfaction,
      score: this.score,
    };
  }
}
