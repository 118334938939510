import { useState } from "react";
import {
  Animated,
  Text,
  View,
  StyleSheet,
  Platform,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { StackHeaderProps } from "@react-navigation/stack";
import { Entypo, FontAwesome5 } from "@expo/vector-icons";
import { COLORS, FONTS, PLATFORM_WIDTH } from "../../constants/ui";
import { animate } from "../util";

const { height } = Dimensions.get("window");
const CLOSED_NAV_HEIGHT = Platform.OS === "web" ? height * 0.1 : height * 0.14;
const OPEN_NAV_HEIGHT = Platform.OS === "web" ? height * 0.2 : height * 0.25;
const LIST_HEIGHT = Platform.OS === "web" ? height * 0.06 : height * 0.1;

export const CustomHeader = ({
  back,
  navigation,
  route,
  options,
}: StackHeaderProps) => {
  const [open, setOpen] = useState(false);
  const [animated, setAnimated] = useState({
    height: new Animated.Value(CLOSED_NAV_HEIGHT),
    opacity: new Animated.Value(0),
  });

  const handleOpenMenu = () => {
    if (open) {
      animate(animated.height, CLOSED_NAV_HEIGHT, 200, false).start(() =>
        animate(animated.opacity, 0, 0, true).start()
      );
      setOpen(false);
    } else {
      animate(animated.height, OPEN_NAV_HEIGHT, 400, false).start(() =>
        animate(animated.opacity, 1, 100, true).start()
      );
      setOpen(true);
    }
  };

  const hamburgerVisible = route.name === "LabDetails";
  return (
    <View style={styles.container}>
      <Animated.View style={[styles.wrapper, { height: animated.height }]}>
        <View style={styles.mainNavWrapper}>
          <TouchableOpacity onPress={back ? navigation.goBack : undefined}>
            <FontAwesome5 name="arrow-left" size={20} color={COLORS.BLACK} />
          </TouchableOpacity>
          <TouchableOpacity>
            <Text style={styles.title}>{options.title}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => hamburgerVisible && handleOpenMenu()}
          >
            <Entypo
              name={open ? "cross" : "menu"}
              size={40}
              color="black"
              style={{
                transform: [{ translateY: 10 }],
                opacity: hamburgerVisible ? 1 : 0,
              }}
            />
          </TouchableOpacity>
        </View>
        {open && (
          <View style={styles.list}>
            <TouchableOpacity onPress={() => null}>
              <Animated.Text
                style={[styles.listItem, { opacity: animated.opacity }]}
              >
                Leave a review
              </Animated.Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => null}>
              <Animated.Text
                style={[styles.listItem, { opacity: animated.opacity }]}
              >
                Browse more tests
              </Animated.Text>
            </TouchableOpacity>
          </View>
        )}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.WHITE,
  },
  wrapper: {
    backgroundColor: COLORS.LIGHTBLUE,
    position: "relative",
    width: PLATFORM_WIDTH,
    alignItems: "center",
    marginRight: "auto",
    marginLeft: "auto",
  },
  title: {
    fontFamily: FONTS.LexendDeca_600,
    fontSize: PLATFORM_WIDTH * 0.03,
  },
  mainNavWrapper: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    position: "absolute",
    height: LIST_HEIGHT,
    zIndex: 10,
    width: PLATFORM_WIDTH,
  },
  list: {
    width: PLATFORM_WIDTH,
    alignItems: "flex-end",
    justifyContent: "center",
    marginTop: Platform.OS === "web" ? 20 : 10,
    paddingHorizontal: 30,
    height: "50%",
    position: "absolute",
    top: LIST_HEIGHT,
  },
  listItem: {
    fontFamily: FONTS.LexendDeca_400,
    paddingVertical: 10,
  },
});
