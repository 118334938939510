import React from "react";
import { StyleSheet } from "react-native";
import { Rating } from "react-native-elements";
import { COLORS } from "../../constants/ui";

interface Props {
  size: number;
  rating: number | undefined;
}

export const StarRating: React.FC<Props> = ({ size, rating }) => {
  return (
    <Rating
      startingValue={rating}
      type="custom"
      imageSize={size}
      ratingColor={COLORS.DARKBLUE}
      tintColor={COLORS.WHITE}
      readonly
    />
  );
};
