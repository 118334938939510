import React, { useState, useEffect } from "react";
import {
  View,
  Pressable,
  Dimensions,
  StyleSheet,
  ScrollView,
} from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import {
  getDocs,
  collection,
  query,
  orderBy,
  OrderByDirection,
  startAt,
  endAt,
} from "firebase/firestore";
import firestore from "../firebase.config";
import { ClientCard, PlatformDropdown } from "../components";
import { useGeolocation } from "../hooks";
import { COLORS, PLATFORM_WIDTH } from "../constants/ui";
import type { RootStackParamList } from "../components/util";
import { ILaboratory, ILabItem } from "../components/util/Clases";
const geofire = require("geofire-common");

type TestDetailsScreenProps = StackScreenProps<
  RootStackParamList,
  "TestDetails"
>;
let { height } = Dimensions.get("window");

export const TestDetails: React.FC<TestDetailsScreenProps> = ({
  navigation,
  route,
}) => {
  const [labs, setLabs] = useState<ILaboratory[]>([]);
  const { testName } = route.params;
  const { getLocationAsync, location } = useGeolocation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<"rating" | "price" | "distance">("rating");
  const [items, setItems] = useState([
    { label: "Distance", value: "distance" },
    { label: "Rating", value: "rating" },
    { label: "Price", value: "price" },
  ]);

  if (!location?.coords.latitude) {
    getLocationAsync();
  }

  const handleSnapshot = (querySnapshot) => {
    const result: ILaboratory[] = [];
    querySnapshot.forEach((doc) => {
      const {
        adress,
        street,
        hours,
        name,
        tests,
        raiting,
        geohash,
        geopoints,
        tel,
      } = doc.data();
      result.push({
        adress,
        street,
        hours,
        name,
        tests,
        raiting,
        geohash,
        geopoints,
        tel,
      });
    });
    if (result.length) {
      setLabs(result);
    }
  };

  const sortByDistance = async () => {
    try {
      const { latitude, longitude } = location?.coords || {};
      if (latitude && longitude) {
        const radiusInM = 120 * 10000;
        const center = [latitude, longitude];
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        for (const b of bounds) {
          const q = query(
            collection(firestore, "laboratories"),
            orderBy("geohash"),
            startAt(b[0]),
            endAt(b[1])
          );
          const querySnapshot = await getDocs(q);
          handleSnapshot(querySnapshot);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortBy = async (phrase: string, direction: OrderByDirection) => {
    try {
      const q = query(
        collection(firestore, "laboratories"),
        orderBy(phrase, direction)
      );
      const querySnapshot = await getDocs(q);
      handleSnapshot(querySnapshot);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (value) {
      case "rating":
        sortBy("raiting", "desc");
        break;
      case "price":
        const labsWithComparablePriceField = labs
          .map(({ tests, ...restProps }) => {
            const chosenTest = tests.find((test) => test.title === testName);
            return {
              tests,
              chosenTest,
              ...restProps,
            };
          })
          .filter(
            (lab): lab is ILaboratory & { chosenTest: ILabItem } =>
              typeof lab.chosenTest !== "undefined"
          );
        const byPrice = labsWithComparablePriceField.sort(
          (a, b) => a.chosenTest.price - b.chosenTest.price
        );
        setLabs([...byPrice]);
        break;
      case "distance":
        sortByDistance();
        break;
      default:
        sortBy("name", "asc");
        break;
    }
  }, [value]);

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <PlatformDropdown
          open={open}
          setOpen={setOpen}
          value={value}
          setValue={setValue}
          items={items}
          setItems={setItems}
        />
        <Pressable
          style={open && styles.overlayOpen}
          onPress={() => setOpen(false)}
        ></Pressable>
        <ScrollView
          style={styles.locationDisplay}
          showsVerticalScrollIndicator={false}
        >
          {labs.length > 0 &&
            labs.map((lab) => (
              <ClientCard
                {...lab}
                key={lab.name}
                onPress={() =>
                  navigation.navigate("LabDetails", {
                    labName: lab.name,
                  })
                }
              />
            ))}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: height,
    alignItems: "center",
    backgroundColor: COLORS.LIGHTBLUE,
  },
  wrapper: {
    justifyContent: "space-between",
    width: PLATFORM_WIDTH,
    height: "100%",
    paddingHorizontal: 20,
    backgroundColor: COLORS.DARKBLUE,
    borderTopRightRadius: 30,
  },
  locationDisplay: {
    borderColor: COLORS.DARKBLUE,
    width: "100%",
    height: height * 0.4,
  },
  overlayOpen: {
    position: "absolute",
    top: 0,
    left: 0,
    width: PLATFORM_WIDTH,
    height: "100%",
    backgroundColor: COLORS.DARKBLUE,
    zIndex: 29,
    opacity: 0.5,
    borderTopRightRadius: 30,
  },
});
