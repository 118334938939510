import React from "react";
import { Text, StyleSheet } from "react-native";

interface Props {
  color: string;
  onPress: () => void;
}

export const LinkButton: React.FC<Props> = ({ children, color, onPress }) => {
  return (
    <Text style={styles({ color }).link} onPress={onPress}>
      {children}
    </Text>
  );
};

const styles = ({ color }: Pick<Props, "color">) =>
  StyleSheet.create({
    link: {
      color,
      textDecorationLine: "underline",
    },
  });
