import type { ILaboratory } from ".";
import type { LocationObject } from "expo-location";

export type UserMetrics = { gender: string; age: string };
export type LoginData = {
  login: string;
  password: string;
};
export type NewsletterData = {
  email: string;
};
export type CorouselItem = { render: any; id: string };

export type RadioItem = {
  text: string;
  selectionColor: string;
  icon: "md-male" | "md-female";
};

export type DataItem = {
  test: string;
  freq: number;
  fromAge: number;
  toAge?: number;
  extra?: Array<string>;
  details: string;
};

export type RootStackParamList = {
  Login: undefined;
  Home: undefined;
  Tests: { gender: string; age: string };
  TestDetails: { testName: string };
  TermsAndConditions: undefined;
  LabDetails: { labName: string };
};

export type SearchType = string | number | boolean;
// TODO: figure out how to type without any so that it would not yell that string | number | boolean is not assignable to type ...
export type ArgPredicate = (item: DataItem) => (arg: any) => boolean;

export enum AdditionalHealthCondition {
  Smoker = "Smoker",
  HeavyDrinker = "Heavy Drinker",
  Diabetic = "Diabetic",
}
