import React from "react";
import {
  Pressable,
  Text,
  StyleSheet,
  Animated,
  View,
  Dimensions,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { FONTS, PLATFORM_WIDTH } from "../../constants/ui";

interface CheckmarkButtonProps {
  onPress: (value: React.ReactNode) => void;
  bgColor: string;
  textColor: string;
  plusIconOpacity: Animated.Value;
  checkIconOpacity: Animated.Value;
  checkIconChangeY: Animated.Value;
  plusIconChangeX: Animated.Value;
}

const { width } = Dimensions.get("window");
const desktop = width > PLATFORM_WIDTH;

export const CheckmarkButton: React.FC<CheckmarkButtonProps> = (props) => {
  const {
    children,
    bgColor,
    textColor,
    onPress,
    plusIconOpacity,
    checkIconOpacity,
    checkIconChangeY,
    plusIconChangeX,
  } = props;

  return (
    <Pressable
      style={[styles.button, { backgroundColor: bgColor }]}
      onPress={() => onPress(children)}
    >
      <View style={styles.iconWrapper}>
        <Animated.View
          style={[
            styles.icon,
            {
              opacity: checkIconOpacity,
              transform: [
                {
                  rotateY: checkIconChangeY.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["90deg", "0deg"],
                  }),
                },
              ],
            },
          ]}
        >
          <Feather name="check" size={17} color={textColor} />
        </Animated.View>
        <Animated.View
          style={{
            opacity: plusIconOpacity,
            transform: [
              {
                rotateX: plusIconChangeX.interpolate({
                  inputRange: [0, 1],
                  outputRange: ["90deg", "0deg"],
                }),
              },
            ],
          }}
        >
          <Feather name="plus" size={17} color={textColor} />
        </Animated.View>
      </View>

      <Text style={[styles.text, { color: textColor }]}>{children}</Text>
    </Pressable>
  );
};
export const styles = StyleSheet.create({
  button: {
    paddingVertical: desktop ? 10 : 7,
    paddingHorizontal: desktop ? 20 : 15,
    borderRadius: 1000,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  text: {
    textAlign: "center",
    fontSize: 15,
    fontFamily: FONTS.LexendDeca_600,
    marginHorizontal: 10,
  },
  icon: {
    position: "absolute",
  },
  iconWrapper: {
    position: "relative",
  },
});
