import React, { forwardRef } from "react";
import {
  Pressable,
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import type { DataItem } from "../util";
import { COLORS, FONTS, PLATFORM_WIDTH } from "../../constants/ui";

interface Props {
  onPress: () => void;
  itemDetails: Pick<DataItem, "test" | "freq">;
  opacity: number;
}

let { height } = Dimensions.get("window");

export const TestCard = forwardRef<View, Props>(
  ({ onPress, itemDetails, opacity }, ref) => {
    const { test, freq } = itemDetails;
    return (
      <View style={[styles.wrapper, { opacity }]} ref={ref}>
        <Pressable onPress={onPress} style={styles.card}>
          <View style={styles.frequencyWrapper}>
            <Text style={styles.frequency}>Once per {freq / 12} yr</Text>
          </View>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{test}</Text>
          </View>
        </Pressable>
        <FontAwesome5
          name="arrow-circle-right"
          size={PLATFORM_WIDTH * 0.07}
          color={COLORS.DARKBLUE}
          style={styles.sideMarker}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  wrapper: {
    height: height * 0.17,
    width: Platform.OS === "web" ? "90%" : "95%",
    backgroundColor: COLORS.WHITE,
    borderRadius: 15,
    marginVertical: 15,
    marginHorizontal: "auto",
    position: "relative",
    overflow: "hidden",
  },
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    zIndex: 3,
    justifyContent: "space-between",
  },
  sideMarker: {
    position: "absolute",
    right: 30,
    bottom: Platform.OS !== "android" ? height * 0.034 : height * 0.02,
  },
  title: {
    fontFamily: FONTS.LexendDeca_600,
    fontSize: height * 0.025,
    textTransform: "capitalize",
    color: COLORS.BLACK,
  },
  titleWrapper: {
    flexGrow: 2,
    width: "70%",
    justifyContent: "center",
    paddingHorizontal: 25,
  },
  frequency: {
    fontFamily: FONTS.LexendDeca_400,
    fontSize: 14,
    color: COLORS.BLACK,
  },
  frequencyWrapper: {
    flexGrow: 1,
    justifyContent: "center",
    backgroundColor: COLORS.LIGHTBLUE,
    width: "100%",
    paddingHorizontal: 25,
  },
});
