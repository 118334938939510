import React, { useState } from "react";
import { Link } from "@react-navigation/native";
import { View, StyleSheet, Text, Dimensions, TextInput } from "react-native";
import { Button, CheckBox } from "react-native-elements";
import { useForm, Controller } from "react-hook-form";
import { LinkButton } from "../commonUI/LinkButton";
import { COLORS, FONTS, PLATFORM_WIDTH } from "../../constants/ui";
import type { NewsletterData } from "../util";

let { height, width } = Dimensions.get("window");

interface Props {
  onSubmit: (data: NewsletterData) => void;
  signupState: "signup-ok" | "user-exists";
}

export const NewsletterSignup: React.FC<Props> = ({
  onSubmit,
  signupState,
}) => {
  const [agreed, setAgreed] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewsletterData>({
    defaultValues: {
      email: "",
    },
  });
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Thank you for using{" "}
        <Text style={styles.standOutText}>Test Reminder!</Text>
      </Text>
      <Text style={styles.text}>Sign up for newsletter</Text>
      <View style={styles.formWrapper}>
        <View style={styles.inputWrapper}>
          <Controller
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Incorrect email",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={styles.input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                placeholder="Email"
              />
            )}
            name="email"
          />
          {errors.email && (
            <Text style={styles.error}>{errors.email.message}</Text>
          )}
          {signupState === "signup-ok" && (
            <Text style={styles.ok}>Awesome, thank you!</Text>
          )}
          {signupState === "user-exists" && (
            <Text style={styles.warning}>
              You have signed up allready, thank you!
            </Text>
          )}
        </View>
        <Button
          title="Sign up"
          buttonStyle={[
            styles.submit,
            {
              backgroundColor: agreed ? COLORS.BLACK : COLORS.RED,
            },
          ]}
          containerStyle={{ height: "100%" }}
          onPress={handleSubmit((data) => {
            if (agreed) {
              onSubmit(data);
              reset();
            }
          })}
        />
      </View>
      <View style={styles.terms}>
        <CheckBox
          checked={agreed}
          checkedColor={COLORS.DARKBLUE}
          onPress={() => setAgreed(!agreed)}
        />
        <Text style={styles.termsText}>
          I do{!agreed && <Text style={styles.termsTextNot}> not</Text>} agree
          for the{" "}
          {width > PLATFORM_WIDTH ? (
            <LinkButton
              color={COLORS.FADED_BLUE}
              onPress={() => window.open("/terms-and-conditions", "_blank")}
            >
              terms and conditions
            </LinkButton>
          ) : (
            <Link to={{ screen: "TermsAndConditions" }} style={styles.link}>
              terms and conditions
            </Link>
          )}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.LIGHTBLUE,
    width: "100%",
    borderRadius: 9,
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    padding: 5,
    paddingBottom: 0,
  },
  text: {
    fontFamily: FONTS.LexendDeca_400,
    color: COLORS.BLACK,
    fontSize: height * 0.02,
    textAlign: "center",
  },
  standOutText: {
    fontFamily: FONTS.LexendDeca_600,
    fontSize: height * 0.03,
  },
  formWrapper: {
    flexDirection: "row",
    padding: 14,
    paddingBottom: 7,
  },
  inputWrapper: {
    flex: 1,
    maxHeight: 40,
  },
  input: {
    padding: 12,
    marginBottom: -7,
    backgroundColor: COLORS.WHITE,
    borderRadius: 3,
    height: "100%",
  },
  error: {
    color: COLORS.RED,
    marginTop: 3,
  },
  ok: {
    color: COLORS.TEAL,
    marginTop: 3,
  },
  warning: {
    color: COLORS.RED,
    marginTop: 3,
  },
  submit: {
    marginLeft: 5,
  },
  terms: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 0,
  },
  termsText: {
    fontFamily: FONTS.LexendDeca_400,
    fontSize: 12,
    color: COLORS.BLACK,
  },
  termsTextNot: {
    fontFamily: FONTS.LexendDeca_400,
    color: COLORS.BLACK,
  },
  link: {
    textDecorationLine: "underline",
  },
});
