import React from "react";
import { Platform, StyleSheet } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import { FONTS, COLORS } from "../../constants/ui";

interface Props {
  open: boolean;
  value: string;
  items: any[];
  setOpen: React.Dispatch<any>;
  setValue: React.Dispatch<any>;
  setItems: React.Dispatch<any>;
}

export const PlatformDropdown: React.FC<Props> = ({
  open,
  setOpen,
  value,
  setValue,
  items,
  setItems,
}) => {
  return (
    <DropDownPicker
      open={open}
      value={value}
      items={items}
      setOpen={setOpen}
      setValue={setValue}
      setItems={setItems}
      placeholder={`Rating`}
      showTickIcon={false}
      closeOnBackPressed={true}
      style={[styles.dropdown, { borderRadius: open ? 30 : 700 }]}
      containerStyle={styles.containerStyle}
      dropDownContainerStyle={styles.dropDownContainerStyle}
      listItemLabelStyle={styles.dropdownListItemLabelStyle}
      selectedItemContainerStyle={styles.dropdownSelectedItemContainerStyle}
      listItemContainerStyle={styles.listItemContainerStyle}
      textStyle={{ fontFamily: FONTS.LexendDeca_600 }}
      maxHeight={300}
      zIndex={30}
    />
  );
};

const styles = StyleSheet.create({
  dropdown: {
    flexDirection: "row",
    paddingHorizontal: 20,
    paddingVertical: Platform.OS === "web" ? 15 : 10,
    maxWidth: "100%",
    backgroundColor: COLORS.LIGHTBLUE,
    marginVertical: 20,
  },
  containerStyle: {
    borderRadius: 700,
  },
  dropDownContainerStyle: {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.WHITE,
    borderRadius: 30,
    marginTop: 30,
  },
  dropdownListItemLabelStyle: {
    color: COLORS.BLACK,
    padding: Platform.OS === "web" ? 20 : 10,
  },
  listItemContainerStyle: {
    height: 50,
    justifyContent: "center",
  },
  dropdownSelectedItemContainerStyle: {
    backgroundColor: COLORS.LIGHTBLUE,
  },
  dropdownCanvas: { minHeight: 200, borderRadius: 700 },
});
