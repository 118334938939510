import { ITestSummary, Gender } from "./interfaces";

export class TestSummary implements ITestSummary {
  public priceRange: [number, number] = [0, 0];

  constructor(
    public title: string,
    public gender: Gender,
    public details: string,
    public frequency: number,
    public fromAge: number,
    public toAge?: number,
    public extras?: string[]
  ) {}
  updatePriceRange(): void {
    //TODO: zaciągnąć z bazy min i max i zupdatować (w konstruktorze?)
  }
  toFirestore() {
    return {
      title: this.title,
      details: this.details,
      frequency: this.frequency,
      fromAge: this.fromAge,
      toAge: this.toAge ? this.toAge : null,
      extras: this.extras ? this.extras : null,
    };
  }
}
