import React, { useState, useRef, useLayoutEffect } from "react";
import { StatusBar } from "expo-status-bar";
import {
  Dimensions,
  Platform,
  StyleSheet,
  View,
  Text,
  findNodeHandle,
} from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { useAppDispatch, setUserData } from "../redux";
import { HealthCheckForm, HeroSVG } from "../components";
import { PLATFORM_WIDTH, COLORS, FONTS } from "../constants/ui";
import type { UserMetrics, RootStackParamList } from "../components/util";

type HomeScreenProps = StackScreenProps<RootStackParamList, "Home">;

const { height: viewportHeight } = Dimensions.get("window");

export const Home: React.FC<HomeScreenProps> = ({ navigation }) => {
  const [svgHeight, setSvgHeight] = useState(0);
  const svgHeightForwardedRef = useRef<View | null>(null);
  const dispatch = useAppDispatch();
  const onSumbit = (data: UserMetrics) => {
    const { gender, age } = data;
    dispatch(setUserData(data));
    navigation.navigate("Tests", { gender, age });
  };

  const homeScreenContainerRef = useRef<View | null>(null);

  useLayoutEffect(() => {
    const parentHandle = findNodeHandle(homeScreenContainerRef.current);
    if (svgHeightForwardedRef.current && parentHandle) {
      svgHeightForwardedRef.current?.measureLayout(
        parentHandle,
        (left, top, width, height) => {
          const svgHeightPerPlatform =
            Platform.OS === "web"
              ? viewportHeight * 0.2
              : viewportHeight - height - top;
          setSvgHeight(svgHeightPerPlatform);
        },
        () => {
          console.log("fail");
        }
      );
    }
  }, [svgHeightForwardedRef.current, homeScreenContainerRef.current]);
  return (
    <View style={styles.container} ref={homeScreenContainerRef}>
      <View style={styles.wrapper}>
        <StatusBar style="auto" />
        <Text style={styles.welcomeText}>Test Reminder</Text>
        <View style={styles.heroWrapper}>
          <HeroSVG
            width={PLATFORM_WIDTH}
            height={svgHeight}
            preserveAspectRatio="xMidYMid meet"
          />
        </View>
        <HealthCheckForm onSubmit={onSumbit} ref={svgHeightForwardedRef} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Platform.OS === "web" ? 50 : 0,
    overflow: "hidden",
    maxHeight: viewportHeight,
  },
  wrapper: {
    width: PLATFORM_WIDTH,
    flex: 1,
    alignItems: "center",
    backgroundColor: COLORS.LIGHTBLUE,
  },
  heroWrapper: {
    padding: 15,
    flexShrink: 1,
  },
  welcomeText: {
    fontSize: 24,
    fontFamily: FONTS.LexendDeca_600,
    color: COLORS.BLACK,
  },
});
