import React, { useState } from "react";
import { Dimensions } from "react-native";
import {
  GoogleMap,
  LoadScript,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import type { GeoPoint } from "firebase/firestore";
import { PLATFORM_WIDTH } from "../../constants/ui";

let { height } = Dimensions.get("window");

const containerStyle = {
  width: PLATFORM_WIDTH * 0.9,
  height: height * 0.25,
  borderRadius: 15,
};

interface Props {
  geopoints: GeoPoint;
  userLocation: [number, number];
  apiKey: string;
}

enum TravelMode {
  DRIVING = "DRIVING",
}

export const ReactGoogleMaps = React.memo<Props>(
  ({ geopoints, userLocation, apiKey }) => {
    const [response, setResponse] =
      useState<google.maps.DirectionsResult | null>(null);
    const { latitude: lat, longitude: lng } = geopoints;
    const [userLat, userLong] = userLocation;
    const destination = { lat, lng };
    const origin = { lat: userLat, lng: userLong };
    const weKnowOurPath =
      destination.lat && destination.lng && origin.lat && origin.lng;

    const directionsCallback = (
      result: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus
    ) => {
      if (result !== null) {
        if (status === "OK") {
          setResponse(result);
        } else {
          console.log("response: ", response);
        }
      }
    };

    return (
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat, lng }}
          zoom={16}
        >
          {weKnowOurPath && !response && (
            <DirectionsService
              options={{ destination, origin, travelMode: TravelMode.DRIVING }}
              callback={directionsCallback}
            />
          )}
          {response && (
            <DirectionsRenderer
              options={{
                directions: response,
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
    );
  }
);
