import React, { useState, useEffect, useRef } from "react";
import AppLoading from "expo-app-loading";
import { Platform, View, StyleSheet, ScrollView, Text } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { doc, getDoc } from "firebase/firestore";
import firestore from "../firebase.config";
import {
  ReactGoogleMaps,
  ExpoGoogleMaps,
  Button,
  ClientCard,
} from "../components";
import { useGeolocation } from "../hooks";
import { COLORS, FONTS, PLATFORM_WIDTH } from "../constants/ui";
import { capitalizeCustom } from "../components/util/functions";
import type { RootStackParamList, ILaboratory } from "../components/util";
import Constants from "expo-constants";

type LabDetailsScreenProps = StackScreenProps<RootStackParamList, "LabDetails">;

export const LabDetails: React.FC<LabDetailsScreenProps> = ({ route }) => {
  const { labName } = route.params;
  const [lab, setLab] = useState<ILaboratory | null>(null);
  const { getLocationAsync, location } = useGeolocation();
  const isMountedRef = useRef(true);

  const { geopoints } = lab || {};

  if (!location?.coords.latitude) {
    getLocationAsync();
  }

  useEffect(() => {
    async function getLab() {
      const docRef = doc(
        firestore,
        "laboratories",
        capitalizeCustom(labName.split("-").join(" "))
      );
      const docSnap = await getDoc(docRef);
      docSnap.exists() && setLab(docSnap.data() as ILaboratory);
    }
    isMountedRef.current && getLab();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  if (
    lab === null ||
    !Constants.manifest?.extra ||
    !geopoints ||
    !location?.coords
  ) {
    return <AppLoading />;
  } else {
    const { MAPS_API_KEY } = Constants.manifest.extra;
    const { latitude, longitude } = location?.coords || {};
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.wrapper}>
          <View style={styles.mapWrapper}>
            {Platform.OS === "web" ? (
              <ReactGoogleMaps
                geopoints={geopoints}
                userLocation={[latitude, longitude]}
                apiKey={MAPS_API_KEY}
              />
            ) : (
              <ExpoGoogleMaps
                geopoints={geopoints}
                userLocation={[latitude, longitude]}
                apiKey={MAPS_API_KEY}
              />
            )}
          </View>
          <View style={{ marginTop: 30, marginBottom: 10 }}>
            <ClientCard {...lab} addressBox />
          </View>
          <Button
            color={COLORS.TEAL}
            textColor={COLORS.BLACK}
            onPress={() => null}
          >
            Make an appointment
          </Button>
          <View style={styles.labDescription}>
            <Text>
              Lab-test Laboratorium S.C. is a microbiological contract
              laboratory based in Katowice, Poland. Since 1996 we have been
              doing tests, assays and studies in the area of industrial
              microbiology. We specialize in testing biocidal activity of
              chemical disinfectants and antiseptics, the kind of assays which
              are necessary, for example, in the European Biocide Registration
              process. Our Laboratory is accredited at Polish Centre for
              Accreditation to confirm the compliance with the EN ISO/IEC 17025
              standard, which specifies general requirements for the competence
              of testing laboratories. We are accredited at the Polish Centre
              for Accreditation, PCA, with the Accreditation Scope No AB 815.
            </Text>
          </View>
        </ScrollView>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: COLORS.LIGHTBLUE,
  },
  wrapper: {
    justifyContent: "flex-start",
    width: PLATFORM_WIDTH,
    backgroundColor: COLORS.DARKBLUE,
    padding: 30,
    borderTopRightRadius: 30,
  },
  mapWrapper: {
    borderRadius: 15,
    alignItems: "center",
    overflow: "hidden",
  },
  labDescription: {
    backgroundColor: COLORS.WHITE,
    marginTop: 30,
    padding: 20,
    fontFamily: FONTS.LexendDeca_400,
    fontSize: 12,
    borderRadius: 15,
  },
});
