import type { DataItem } from "../components";

export const women: DataItem[] = [
  {
    test: "Cytology",
    freq: 12,
    fromAge: 21,
    details:
      "Cytology is the exam of a single cell type, as often found in fluid specimens. It's mainly used to diagnose or screen for cancer. It's also used to screen for fetal abnormalities, for pap smears, to diagnose infectious organisms, and in other screening and diagnostic areas.",
  },
  {
    test: "Breast ultrasound",
    freq: 12,
    fromAge: 40,
    toAge: 50,
    details:
      "Breast ultrasound is an imaging test that uses sound waves to look at the inside of your breasts. It can help your healthcare provider find breast problems. It also lets your healthcare provider see how well blood is flowing to areas in your breasts.",
  },
  {
    test: "Mammography",
    freq: 12,
    fromAge: 50,
    details:
      "A mammogram is an X-ray picture of the breast. Doctors use a mammogram to look for early signs of breast cancer. Regular mammograms are the best tests doctors have to find breast cancer early, sometimes up to three years before it can be felt.",
  },
];
