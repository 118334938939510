import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { Overlay, Button, Input } from "react-native-elements";
import { Feather } from "@expo/vector-icons";
import { useForm, Controller } from "react-hook-form";
import { collection, doc, setDoc } from "firebase/firestore";
import firestore from "../../../firebase.config";
import { LabelledSlider } from "../../commonUI/LabelledSlider";
import { StarRating } from "../../commonUI/StarRating";
import { COLORS, PLATFORM_WIDTH } from "../../../constants/ui";
import type { IReview } from "../../util";

let { width } = Dimensions.get("window");

interface Props {
  visible: boolean;
  onPress: () => void;
  labId: string;
}

export const ReviewModal: React.FC<Props> = ({ visible, onPress, labId }) => {
  const [step, setStep] = useState<1 | 2>(1);
  const [score, setScore] = useState(0);
  const [stepHeight, setstepHeight] = useState(0);
  const [sending, setSending] = useState(false);
  const lastStep = step === 2;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<IReview>({
    defaultValues: {
      reviewer: "",
      reviewText: "",
      customerService: 0,
      parking: 0,
      waitingTime: 0,
      higene: 0,
      overallSatisfaction: 0,
    },
  });

  const values = watch([
    "customerService",
    "higene",
    "overallSatisfaction",
    "parking",
    "waitingTime",
  ]);

  const handleNext = async (data: IReview) => {
    !lastStep && setStep(2);
    if (lastStep) {
      setSending(true);
      await setDoc(doc(collection(firestore, "reviews")), {
        ...data,
        lab_id: labId,
        score,
      });
      setSending(false);
      onPress();
      reset();
    }
  };

  const calculateScore = (input: number[]) => {
    return input.reduce((total: number, current: number, index) => {
      return index < input.length - 1
        ? total + current
        : (total + current) / input.length;
    }, 0);
  };

  useEffect(() => {
    setScore(calculateScore(values) / 2);
  }, [calculateScore]);

  return (
    <Overlay
      isVisible={visible}
      onBackdropPress={onPress}
      overlayStyle={styles.modalWrapper}
    >
      <StarRating rating={score} size={30} />
      {step === 1 && (
        <View
          onLayout={({ nativeEvent: { layout } }) =>
            setstepHeight(layout.height)
          }
        >
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <LabelledSlider
                label="Customer service"
                value={value}
                onChange={onChange}
              />
            )}
            name="customerService"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <LabelledSlider
                label="Parking"
                value={value}
                onChange={onChange}
              />
            )}
            name="parking"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <LabelledSlider
                label="Waiting time"
                value={value}
                onChange={onChange}
              />
            )}
            name="waitingTime"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <LabelledSlider
                label="Higene"
                value={value}
                onChange={onChange}
              />
            )}
            name="higene"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <LabelledSlider
                label="Overall satisfaction"
                value={value}
                onChange={onChange}
              />
            )}
            name="overallSatisfaction"
          />
        </View>
      )}
      {step === 2 && (
        <View style={[styles.textInputsWrapper, { height: stepHeight }]}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                placeholder="Email"
                inputStyle={styles.inputStyles}
              />
            )}
            name="reviewer"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                placeholder="Comment"
                leftIcon={{ type: "font-awesome", name: "comment" }}
                value={value}
                onChangeText={onChange}
                inputStyle={styles.inputStyles}
              />
            )}
            name="reviewText"
          />
        </View>
      )}
      <View style={styles.buttonWrapper}>
        <Button
          icon={
            <Feather name={"arrow-down"} size={30} color={COLORS.FADED_BLUE} />
          }
          buttonStyle={[
            styles.stepButton,
            { backgroundColor: COLORS.LIGHTBLUE },
          ]}
          onPress={onPress}
        />
        <View style={styles.buttonWrapper}>
          {lastStep && (
            <Button
              icon={
                <Feather
                  name={"arrow-left"}
                  size={30}
                  color={COLORS.FADED_BLUE}
                />
              }
              buttonStyle={[
                styles.stepButton,
                { backgroundColor: COLORS.LIGHTBLUE, marginRight: 7 },
              ]}
              onPress={() => setStep(1)}
            />
          )}
          <Button
            loading={sending}
            icon={
              <Feather
                name={lastStep ? "arrow-up" : "arrow-right"}
                size={30}
                color={COLORS.FADED_BLUE}
              />
            }
            buttonStyle={[
              styles.stepButton,
              { backgroundColor: COLORS.DARKBLUE },
            ]}
            onPress={handleSubmit((data) => handleNext(data))}
          />
        </View>
      </View>
    </Overlay>
  );
};

const styles = StyleSheet.create({
  modalWrapper: {
    width: width > PLATFORM_WIDTH ? PLATFORM_WIDTH * 0.7 : PLATFORM_WIDTH,
    borderRadius: 10,
    backgroundColor: COLORS.LIGHTBLUE,
  },
  buttonWrapper: { flexDirection: "row", justifyContent: "space-between" },
  textInputsWrapper: {
    padding: 10,
  },
  stepButton: {
    borderRadius: 1000,
    width: 50,
    height: 50,
  },
  reviewText: {
    padding: 10,
    borderColor: COLORS.BLACK,
    borderRadius: 5,
  },
  inputStyles: { padding: 10 },
});
