const geofire = require("geofire-common");
import { IAddress } from "./interfaces";

export class Address implements IAddress {
  public readonly geoHash: string;

  constructor(
    public city: string,
    public street: string,
    public code: string,
    public tel: string,
    public lat: number,
    public long: number,
    public hours: { from: string; to: string }
  ) {
    this.geoHash = geofire.geohashForLocation([lat, long]);
  }
  updateData(key: "city" | "street" | "code" | "tel", newData: string) {
    switch (key) {
      case "city":
        if (newData.length === 0 || newData === this.city) return;
        this.city === newData;
        break;
      case "street":
        if (newData.length === 0 || newData === this.street) return;
        this.street === newData;
        break;
      case "code":
        if (newData.length === 0 || newData === this.code) return;
        if (!/"\d{2}-\d{3}"/.test(newData)) {
          throw new Error("Incorrect postal code format");
        }
        this.code === newData;
        break;
      case "tel":
        if (newData.length === 0 || newData === this.tel) return;
        this.tel === newData;
        break;
      default:
        throw new Error("Incorrect key parameter");
    }
  }
  toFirestore(): IAddress {
    return {
      city: this.city,
      street: this.street,
      code: this.code,
      tel: this.tel,
      geoHash: this.geoHash,
      hours: this.hours,
    };
  }
}
