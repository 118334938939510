import { createSlice } from "@reduxjs/toolkit";
import type { UserMetrics } from "../../components";

const initialState: UserMetrics = {
  gender: "",
  age: "",
};

const userMetricsSlice = createSlice({
  name: "Users Metrics Data",
  initialState,
  reducers: {
    setUserData(state, { payload: { gender, age } }) {
      state.age = age;
      state.gender = gender;
    },
  },
});

export const { setUserData } = userMetricsSlice.actions;
export default userMetricsSlice.reducer;
