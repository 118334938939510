import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import type { GeoPoint } from "firebase/firestore";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import MapViewDirections from "react-native-maps-directions";
import { COLORS, PLATFORM_WIDTH } from "../../constants/ui";

const { height } = Dimensions.get("window");

interface Props {
  geopoints: GeoPoint;
  userLocation: [number, number];
  apiKey: string;
}

export const ExpoGoogleMaps: React.FC<Props> = ({
  apiKey,
  userLocation,
  geopoints,
}) => {
  const [userLat, userLong] = userLocation;
  const initialRegionReady = userLat && userLong;

  const origin = { latitude: userLat, longitude: userLong };

  return (
    <View style={styles.container}>
      {initialRegionReady ? (
        <MapView
          style={styles.map}
          provider={PROVIDER_GOOGLE}
          initialRegion={{
            latitude: userLat,
            longitude: userLong,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          }}
        >
          <MapViewDirections
            origin={origin}
            destination={geopoints}
            apikey={apiKey}
            optimizeWaypoints={true}
            strokeWidth={4}
            strokeColor={COLORS.DARKBLUE}
          />
        </MapView>
      ) : (
        <MapView
          style={styles.map}
          provider={PROVIDER_GOOGLE}
          initialRegion={{
            latitude: 52.23168393459263,
            longitude: 21.00596675251617,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    overflow: "hidden",
  },
  map: {
    width: PLATFORM_WIDTH * 0.9,
    height: height * 0.25,
    borderRadius: 15,
    overflow: "hidden",
  },
});
