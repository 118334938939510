import type { DataItem } from "../components";

export const common: DataItem[] = [
  {
    test: "dentist",
    freq: 6,
    fromAge: 21,
    details:
      "Dentists diagnose and treat dental issues and help patients develop better oral hygiene regimens. They clean teeth, correct bite issues, perform surgeries and extractions and perform other duties to ensure that the teeth and mouth are healthy.",
  },
  {
    test: "blood count",
    freq: 12,
    fromAge: 21,
    details:
      "A complete blood count (CBC) is a blood test used to evaluate your overall health and detect a wide range of disorders, including anemia, infection and leukemia. A complete blood count test measures several components and features of your blood, including: Red blood cells, which carry oxygen.",
  },
  {
    test: "creatinine",
    freq: 12,
    fromAge: 21,
    details:
      "Creatinine is a chemical compound left over from energy-producing processes in your muscles. Healthy kidneys filter creatinine out of the blood. Creatinine exits your body as a waste product in urine.",
  },
  {
    test: "electrolytes",
    freq: 12,
    fromAge: 21,
    extra: ["sodium", "potassium", "magnesium"],
    details:
      "Electrolytes are minerals in your body that have an electric charge. They are in your blood, urine, tissues, and other body fluids. Electrolytes are important because they help: Balance the amount of water in your body. Balance your body's acid/base (pH) level.",
  },
  {
    test: "fasting glucose",
    freq: 12,
    fromAge: 21,
    details:
      "Fasting blood glucose: A test to determine how much glucose (sugar) is in a blood sample after an overnight fast. The fasting blood glucose test is commonly used to detect diabetes mellitus. A blood sample is taken in a lab, physician's office, or hospital. The test is done in the morning, before the person has eaten.",
  },
  {
    test: "blood pressure ",
    freq: 12,
    fromAge: 21,
    details:
      "Blood pressure is the pressure of blood pushing against the walls of your arteries. Arteries carry blood from your heart to other parts of your body. Your blood pressure normally rises and falls throughout the day.",
  },
  {
    test: "body weight",
    freq: 12,
    fromAge: 21,
    details:
      "Strictly speaking, body weight is the measurement of weight without items located on the person. Practically though, body weight may be measured with clothes on, but without shoes or heavy accessories such as mobile phones and wallets, and using manual or digital weighing scales.",
  },
  {
    test: "eye examination",
    freq: 24,
    fromAge: 21,
    details:
      "An eye exam involves a series of tests to evaluate your vision and check for eye diseases. Your eye doctor is likely to use various instruments, shine bright lights at your eyes and request that you look through an array of lenses. Each test during an eye exam evaluates a different aspect of your vision or eye health.",
  },
  {
    test: "Lipidogram",
    freq: 24,
    fromAge: 21,
    details:
      "A lipid profile or lipid panel is a panel of blood tests used to find abnormalities in lipids, such as cholesterol and triglycerides. The results of this test can identify certain genetic diseases and can determine approximate risks for cardiovascular disease, certain forms of pancreatitis, and other diseases.",
  },
  {
    test: "Colonoscopy",
    freq: 120,
    fromAge: 50,
    extra: ["Smokers once in 2-3 years"],
    details:
      "Colonoscopy  is an exam used to detect changes or abnormalities in the large intestine (colon) and rectum.",
  },
  {
    test: "Low-dose computed tomography of the chest",
    freq: 120,
    fromAge: 50,
    details:
      "A procedure that uses a computer linked to an x-ray machine that gives off a very low dose of radiation to make a series of detailed pictures of areas inside the body. The pictures are taken from different angles and are used to create 3-D views of tissues and organs. Low-dose computed tomography is recommended as a screening test for adults who have a high risk of developing lung cancer based on their age and smoking history. Also called LDCT and low-dose CT scan.",
  },
];
