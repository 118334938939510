import React from "react";
import { View, StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { LoginForm } from "../components/forms";
import { COLORS } from "../constants/ui";
import type { RootStackParamList, LoginData } from "../components/util";
import Constants from "expo-constants";

type LoginScreenProps = StackScreenProps<RootStackParamList, "Login">;

export const Login: React.FC<LoginScreenProps> = ({ navigation }) => {
  const { APP_LOGIN, APP_PASSWORD } = Constants.manifest?.extra!;

  const handleSubit = ({ login, password }: LoginData) => {
    if (login === APP_LOGIN && password === APP_PASSWORD) {
      navigation.navigate("Home");
    }
  };

  return (
    <View style={styles.container}>
      <LoginForm
        onSubmit={handleSubit}
        logSecret={APP_LOGIN}
        passSecret={APP_PASSWORD}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
    alignItems: "center",
  },
});
