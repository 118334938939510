import type { DataItem, ArgPredicate } from ".";

function removeDuplicates(duplicates: DataItem[]) {
  const flag: Record<string, boolean> = {};
  const unique: DataItem[] = [];
  duplicates.forEach((el) => {
    if (!flag[el.test]) {
      flag[el.test] = true;
      unique.push(el);
    }
  });
  return unique;
}

export const filterByAge = (item: DataItem) => (age: string) => {
  if (item.toAge) {
    return item.fromAge <= parseInt(age) && item.toAge >= parseInt(age);
  } else {
    return item.fromAge <= parseInt(age);
  }
};

export const getDataItems =
  (comonData: DataItem[], genderData: DataItem[], searchVal: any) =>
  (predicate: ArgPredicate) => {
    const commonItems = comonData.filter((item) => predicate(item)(searchVal));
    const genderItems = genderData.filter((item) => predicate(item)(searchVal));

    return removeDuplicates([...commonItems, ...genderItems]);
  };
