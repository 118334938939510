import React, { useState } from "react";
import { Dimensions, Pressable, StyleSheet, View, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import type { RadioItem } from "../util";
import { COLORS, FONTS } from "../../constants/ui";

interface Props {
  data: RadioItem[];
  onSelect: (item: string) => void;
}
let { height } = Dimensions.get("window");
export const RadioGroup: React.FC<Props> = ({ data, onSelect }) => {
  const [userOption, setUserOption] = useState("");
  const selectHandler = (value: string) => {
    onSelect(value);
    setUserOption(value);
  };

  return (
    <View>
      <View style={styles.buttonGroup}>
        {data.map((genderOption, i) => {
          const selected = genderOption.text === userOption;
          const beforeInteractionInputStyles = {
            backgroundColor: COLORS.LIGHTBLUE,
          };
          const afterInteractionInputStyles = {
            backgroundColor: selected
              ? genderOption.selectionColor
              : COLORS.FADED_BLUE,
          };

          return (
            <View key={genderOption.text} style={styles.buttonWrap}>
              <Pressable
                onPress={() => selectHandler(genderOption.text)}
                style={[
                  styles.radioInput,
                  { marginRight: i % 2 ? 0 : 7, marginLeft: i % 2 ? 7 : 0 },
                  userOption
                    ? afterInteractionInputStyles
                    : beforeInteractionInputStyles,
                ]}
              >
                <Text
                  style={[
                    styles.itemText,
                    {
                      color: selected ? COLORS.BLACK : COLORS.DARKBLUE,
                    },
                  ]}
                >
                  {genderOption.text}
                </Text>
                <View>
                  <Ionicons
                    name={genderOption.icon}
                    size={33}
                    color={selected ? COLORS.BLACK : COLORS.DARKBLUE}
                  />
                </View>
              </Pressable>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 17,
    fontWeight: "300",
    marginBottom: 10,
  },
  buttonWrap: { flex: 1 },
  buttonGroup: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  radioInput: {
    minWidth: "40%",
    height: height * 0.15,
    borderRadius: 10,

    justifyContent: "center",
    alignItems: "center",
  },

  itemText: {
    fontSize: 23,
    fontFamily: FONTS.LexendDeca_600,
  },
});
