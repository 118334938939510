import { Address } from "./Address";
import type { ILabItem, IAddress, ILaboratory } from "./interfaces";

export class Laboratory implements ILaboratory {
  public adress: IAddress;
  public tests: ILabItem[] = [];
  public rating?: number;

  constructor(
    public name: string,
    city: string,
    street: string,
    code: string,
    tel: string,
    lat: number,
    long: number,
    public hours: { from: string; to: string },
    public photo?: string
  ) {
    this.name = name;
    this.adress = new Address(
      city,
      street,
      code,
      tel,
      lat,
      long,
      hours
    ).toFirestore();
  }
  setTests(tests: Array<ILabItem>): Laboratory | undefined {
    if (!tests.length) return;
    this.tests = tests;
    return this;
  }
  addTest(newTest: ILabItem): Laboratory | undefined {
    const index = this.tests.findIndex(({ title }) => title === newTest.title);
    if (index > -1) return;
    this.tests.push(newTest);
    return this;
  }
  removeTest(testName: string) {
    const index = this.tests.findIndex(({ title }) => title === testName);
    if (index === -1) return;
    this.tests.splice(index, 1);
  }
  addPhoto(newPhoto: string) {
    if (newPhoto === "" || newPhoto === this.photo) return;
    this.photo = newPhoto;
  }
  removePhoto() {
    this.photo = "";
  }
  toFirestore() {
    return {
      name: this.name,
      adress: this.adress,
      tests: this.tests?.length ? this.tests : null,
      rating: this.rating ? this.rating : 0,
    };
  }
}
