import * as React from "react";
import Svg, { SvgProps, Path, Circle } from "react-native-svg";

interface Props {
  arrowfill: string;
  circlefill: string;
}

export const GoNextSVG: React.FC<Props & SvgProps> = (
  props,
  { arrowfill, circlefill }
) => (
  <Svg width={34} height={34} fill="none" {...props}>
    <Path
      d="M9 17h14M19 12l4.294 4.294a.999.999 0 0 1-.001 1.413L19 22"
      stroke={props.arrowfill}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Circle cx={17} cy={17} r={16.5} stroke={props.circlefill} />
  </Svg>
);
