import React from "react";
import {
  Pressable,
  Text,
  StyleSheet,
  Platform,
  GestureResponderEvent,
} from "react-native";
import { FONTS, COLORS } from "../../constants/ui";

interface Props {
  children: string;
  color: string;
  textColor: string;
  onPress: (event: GestureResponderEvent) => void;
}

export const Button: React.FC<Props> = ({
  children,
  color,
  textColor,
  onPress,
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        styles({ color, textColor }).button,
        {
          backgroundColor: pressed ? COLORS.WHITE : color,
        },
      ]}
    >
      <Text style={styles({ color, textColor }).text}>{children}</Text>
    </Pressable>
  );
};

const styles = ({ color, textColor }: Pick<Props, "color" | "textColor">) =>
  StyleSheet.create({
    button: {
      backgroundColor: color,
      padding: Platform.OS === "web" ? 20 : 15,
      borderRadius: 1000,
      justifyContent: "center",
      width: "100%",
      alignSelf: "center",
    },
    text: {
      color: textColor,
      textAlign: "center",
      fontSize: 24,
      fontFamily: FONTS.LexendDeca_600,
    },
  });
