import type { DataItem } from "../components";

export const men: DataItem[] = [
  {
    test: "Per rectum",
    freq: 12,
    fromAge: 45,
    details:
      "Rectal examination consists of visual inspection of the perianal skin, digital palpation of the rectum, and assessment of neuromuscular function of the perineum.",
  },
  {
    test: "PSA",
    freq: 12,
    fromAge: 45,
    extra: ["<1 ng/ml 1x/4yr", "1-2 ng/ml 1x/2yr", ">2 ng/ml 1x/1yr"],
    details:
      "The PSA test is a blood test used primarily to screen for prostate cancer. The test measures the amount of prostate-specific antigen (PSA) in your blood. PSA is a protein produced by both cancerous and noncancerous tissue in the prostate, a small gland that sits below the bladder in males",
  },
];
