import React, { useEffect, useState, useRef } from "react";
import Swiper from "react-native-web-swiper";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  Platform,
} from "react-native";
import { StarRating } from "./StarRating";
import { COLORS, FONTS, PLATFORM_WIDTH } from "../../constants/ui";
import type { ILaboratory, IReview } from "../util";
import firestore from "../../firebase.config";
import { getDocs, collection, query, where } from "firebase/firestore";
import { FontAwesome5 } from "@expo/vector-icons";

let { height } = Dimensions.get("window");

interface Props {
  onPress?: () => void;
  addressBox?: boolean;
}

type CarouselReview = Pick<IReview, "reviewText" | "reviewer" | "score">;

export const ClientCard: React.FC<ILaboratory & Props> = ({
  adress,
  street,
  hours,
  name,
  tel,
  raiting,
  onPress,
  addressBox,
}) => {
  const [reviews, setReviews] = useState<CarouselReview[]>([]);
  const { city, code } = adress;
  const isMountedRef = useRef(true);

  useEffect(() => {
    async function getReviews() {
      const querySnapshot = await getDocs(
        query(collection(firestore, "reviews"), where("lab_id", "==", name))
      );
      querySnapshot.forEach((doc) => {
        const { reviewText, reviewer, score, lab_id } = doc.data();
        setReviews((prev) => [
          ...prev,
          { reviewText, reviewer, score, lab_id },
        ]);
      });
    }
    isMountedRef.current && getReviews();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const RenderItem = ({ reviewText, score, reviewer }: CarouselReview) => {
    return (
      <View>
        <Text style={{ fontSize: 10, fontFamily: FONTS.LexendDeca_400 }}>
          {reviewer}{" "}
          <Text style={{ fontWeight: "bold" }}>
            {Math.round(Number(score))} / 10
          </Text>
        </Text>
        <Text
          numberOfLines={4}
          ellipsizeMode="tail"
          style={{
            marginTop: 7,
            fontSize: 12,
            fontFamily: FONTS.LexendDeca_400,
            maxWidth: PLATFORM_WIDTH * 0.8,
          }}
        >
          {reviewText}
        </Text>
      </View>
    );
  };

  return (
    <Pressable
      style={[
        styles.container,
        {
          height: addressBox ? "auto" : height * 0.43,
          flex: Platform.OS === "web" ? undefined : 1,
        },
      ]}
      onPress={onPress}
    >
      <View style={styles.infoSection}>
        <View style={styles.infoWrapper}>
          <View>
            <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
              {name}
            </Text>
          </View>
          <Text style={styles.text}>{street}</Text>
          <Text style={styles.text}>
            {code} {city}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 15,
              marginBottom: 7,
            }}
          >
            <FontAwesome5 name="phone-alt" size={12} color={COLORS.DARKBLUE} />
            <Text style={[styles.text, { marginLeft: 5 }]}>{tel}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FontAwesome5 name="clock" size={12} color={COLORS.DARKBLUE} />
            <Text style={[styles.text, { marginLeft: 5 }]}>
              {hours.from} - {hours.to}
            </Text>
          </View>
        </View>
        <View style={{ alignItems: "center" }}>
          <StarRating rating={raiting} size={15} />
          {!addressBox && (
            <FontAwesome5
              name="arrow-circle-right"
              size={PLATFORM_WIDTH * 0.07}
              color={COLORS.DARKBLUE}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            />
          )}
        </View>
      </View>
      {!addressBox && (
        <View style={styles.reviewSection}>
          {reviews.length > 4 && (
            <Swiper
              timeout={5}
              containerStyle={{
                flex: 1,
                paddingHorizontal: 20,
                paddingVertical: 10,
              }}
            >
              <RenderItem {...reviews[0]} />
              <RenderItem {...reviews[1]} />
              <RenderItem {...reviews[2]} />
              <RenderItem {...reviews[3]} />
              <RenderItem {...reviews[4]} />
            </Swiper>
          )}
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: COLORS.WHITE,
    marginBottom: 20,
    zIndex: 7,
    borderRadius: 15,
    overflow: "hidden",
  },
  infoSection: {
    height: "65%",
    width: "100%",
    flexDirection: "row",
    padding: 20,
    zIndex: 10,
  },
  reviewSection: {
    height: "35%",
    width: "100%",
    backgroundColor: COLORS.LIGHTBLUE,
  },
  infoWrapper: {
    flex: 1,
    paddingRight: 25,
  },
  title: {
    fontFamily: FONTS.LexendDeca_600,
    fontSize: PLATFORM_WIDTH * 0.043,
    color: COLORS.BLACK,
    marginBottom: Platform.OS === "web" ? 20 : 5,
  },
  text: {
    fontFamily: FONTS.LexendDeca_400,
    color: COLORS.BLACK,
  },
});
